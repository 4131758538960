/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import {
    Input,
    Form,
    FormGroup,
    Label,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter,
    Row,
    Col,
    Button,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import 'react-dropdown/style.css';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import ReactGA from 'react-ga';
import PhoneInput from 'react-phone-input-2';

const queryString = require('query-string');

class CompanyForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyId: '',
            disabled: false,
            error: '',
            name: '',
            nameState: '',
            contactFirstName: '',
            contactFirstNameState: '',
            contactLastName: '',
            contactLastNameState: '',
            contactPhoneNumber: '',
            contactPhoneNumberState: '',
            contactEmailAddress: '',
            contactEmailAddressState: '',
            welcome: '',
            loading: true,
        };
    }

    contactPhoneNumberUpdated(phone) {
        let reg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (reg.test(phone.substring(1)) === false) {
            this.setState({
                contactPhoneNumber: phone,
                contactPhoneNumberState: 'has-danger',
            });
        } else {
            this.setState({
                contactPhoneNumber: phone,
                contactPhoneNumberState: 'has-success',
            });
        }
    }

    componentDidMount() {
        //are we logged in?
        //yes
        if (
            localStorage.getItem('LOGGEDIN') &&
            localStorage.getItem('LOGGEDIN') === 'true' &&
            localStorage.getItem('DATEEXPIRES') &&
            new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
        ) {
            ReactGA.pageview(window.location.pathname + window.location.search);

            var qs = queryString.parse(this.props.location.search);

            //do we have a group id?
            //yes
            if (qs['id']) {
                //get the company from the server
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        SECRET: localStorage.getItem('SECRET'),
                        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
                    },
                };

                fetch(window.configuration.CompanyURL + '?id=' + qs['id'], requestOptions)
                    .then(async (response) => {
                        // check for error response
                        //did we get an error?
                        //yes
                        if (!response.ok) {
                            //set the error
                            this.setState({
                                error: 'We were unable to edit the company',
                                disabled: true,
                                loading: false,
                            });
                        }
                        //did we get an error?
                        //no
                        else {
                            var companyResponse = await response.json();

                            //did we get a good one?
                            //yes
                            if (companyResponse.id) {
                                let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

                                this.setState({
                                    error: '',
                                    loading: false,
                                    disabled: false,
                                    companyId: companyResponse.id,
                                    welcome: companyResponse.welcome ? companyResponse.welcome : '',
                                    name: companyResponse.name,
                                    nameState: 'has-success',
                                    contactFirstName: companyResponse.contactFirstName,
                                    contactFirstNameState: companyResponse.contactFirstName
                                        ? 'has-success'
                                        : 'has-danger',
                                    contactLastName: companyResponse.contactLastName,
                                    contactLastNameState: companyResponse.contactLastName
                                        ? 'has-success'
                                        : 'has-danger',
                                    contactPhoneNumber: companyResponse.contactPhoneNumber,
                                    contactPhoneNumberState: companyResponse.contactPhoneNumber
                                        ? 'has-success'
                                        : 'has-danger',
                                    contactEmailAddress: companyResponse.contactEmailAddress,
                                    contactEmailAddressState:
                                        reg.test(companyResponse.contactEmailAddress) === true
                                            ? 'has-success'
                                            : 'has-danger',
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            error: error,
                            disabled: false,
                            loading: false,
                        });
                    });
            }
            //do we have a group id?
            //no
            else {
                this.setState({ loading: false });
            }
        }
        //are we logged in?
        //no
        else {
            this.props.history.push('/a/logout');
        }
    }

    _handleKeyDown = (e) => {
        try {
            //did they press enter?
            //yes
            if (e.key === 'Enter') {
                this.submitForm(null, null);
            }
        } catch (error) {
            console.log(error);
        }
    };

    //this submits the form
    submit(e, navigation) {
        //did we disable the button?
        //yes
        if (this.state.disabled) {
            return;
        }

        //do we have a name?
        //no
        if (this.state.name.length === 0) {
            this.setState({
                error: 'Please enter the name of the company',
            });
            return;
        }
        //do we have a contactFirstName?
        //no
        else if (this.state.contactFirstName.length === 0) {
            this.setState({
                error: 'Please enter the first name of our company contact',
            });
            return;
        }
        //do we have a contactLastName?
        //no
        else if (this.state.contactLastName.length === 0) {
            this.setState({
                error: 'Please enter the last name of our contact',
            });
            return;
        }
        //do we have a contactEmailAddress?
        //no
        else if (this.state.contactEmailAddress.length === 0) {
            this.setState({
                error: 'Please enter the email address of the contact',
            });
            return;
        }

        this.setState({
            disabled: true,
            error: '',
            loading: true,
        });

        // POST request using fetch with error handling
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                SECRET: localStorage.getItem('SECRET'),
                PASSPHRASE: localStorage.getItem('PASSPHRASE'),
            },
            body: JSON.stringify({
                //we set id because this is the employee record
                id: this.state.companyId,
                name: this.state.name,
                welcome: this.state.welcome,
                contactFirstName: this.state.contactFirstName,
                contactLastName: this.state.contactLastName,
                contactPhoneNumber: this.state.contactPhoneNumber,
                contactEmailAddress: this.state.contactEmailAddress,
            }),
        };

        fetch(window.configuration.CompanyURL, requestOptions)
            .then(async (response) => {
                // check for error response
                //did we get an error?
                //yes
                if (!response.ok) {
                    //did we get an unauthorized?
                    //yes
                    if (response.status === 401) {
                        this.props.history.push('/a/logout');
                    }
                    //did we get an unauthorized?
                    //no
                    else {
                        //set the error
                        this.setState({
                            error: 'We were unable to add the company',
                            disabled: false,
                            loading: false,
                        });
                    }
                }
                //did we get an error?
                //no
                else {
                    //send them to the list
                    this.props.history.push('/in/l/companies');
                }
            })
            .catch((error) => {
                this.setState({
                    error: error,
                    disabled: false,
                    loading: false,
                });
            });
    }

    render() {
        return (
            <>
                {this.state.loading ? (
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                ) : (
                    ''
                )}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12} style={{ maxWidth: 900 }}>
                            <Form>
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">
                                            {this.state.companyId ? 'Update company' : 'Add company'}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <FormGroup className={'has-label ' + this.state.nameState}>
                                                    <Label>Name *</Label>
                                                    <Input
                                                        type="text"
                                                        style={{ textTransform: 'capitalize' }}
                                                        maxLength="250"
                                                        ref={(input) => {
                                                            this.nameInput = input;
                                                        }}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                name: e.target.value,
                                                                nameState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        onKeyDown={this.handleKeyDown}
                                                        autoFocus
                                                        autoCorrect="true"
                                                        value={this.state.name}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <FormGroup className={'has-label ' + this.state.contactFirstNameState}>
                                                    <Label>Contact First Name</Label>
                                                    <Input
                                                        type="text"
                                                        style={{ textTransform: 'capitalize' }}
                                                        maxLength="50"
                                                        ref={(input) => {
                                                            this.contactFirstNameInput = input;
                                                        }}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                contactFirstName: e.target.value,
                                                                contactFirstNameState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        onKeyDown={this.handleKeyDown}
                                                        autoCorrect="true"
                                                        value={this.state.contactFirstName}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <FormGroup className={'has-label ' + this.state.contactLastNameState}>
                                                    <Label>Contact Last Name</Label>
                                                    <Input
                                                        type="text"
                                                        style={{ textTransform: 'capitalize' }}
                                                        maxLength="100"
                                                        ref={(input) => {
                                                            this.contactLastNameInput = input;
                                                        }}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                contactLastName: e.target.value,
                                                                contactLastNameState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        onKeyDown={this.handleKeyDown}
                                                        autoCorrect="true"
                                                        value={this.state.contactLastName}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <FormGroup
                                                    className={'has-label ' + this.state.contactEmailAddressState}
                                                >
                                                    <Label>Contact Email Address *</Label>
                                                    <Input
                                                        type="text"
                                                        maxLength="200"
                                                        ref={(input) => {
                                                            this.contactEmailAddressInput = input;
                                                        }}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                contactEmailAddress: e.target.value,
                                                                contactEmailAddressState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        onKeyDown={this.handleKeyDown}
                                                        autoCorrect="true"
                                                        value={this.state.contactEmailAddress}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <FormGroup
                                                    className={'has-label ' + this.state.contactPhoneNumberState}
                                                >
                                                    <Label>Contact Phone Number *</Label>
                                                    <PhoneInput
                                                        autoFocus
                                                        inputProps={{
                                                            name: 'phone',
                                                            required: true,
                                                        }}
                                                        placeholder="(512) 559-1228"
                                                        country={'us'}
                                                        regions={'north-america'}
                                                        disableDropdown={true}
                                                        disableCountryCode={true}
                                                        countryCodeEditable={true}
                                                        onKeyDown={this._handleKeyDown}
                                                        inputStyle={{
                                                            fontSize: 16,
                                                            height: 40,
                                                            paddingLeft: 50,
                                                            backgroundColor: '#FFFFFF',
                                                            color: '#000000',
                                                            width: '100%',
                                                        }}
                                                        value={this.state.contactPhoneNumber}
                                                        onChange={(text) => this.contactPhoneNumberUpdated(text)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FormGroup className={'has-label ' + this.state.welcomeState}>
                                                    <Label>Optional custom welcome message</Label>
                                                    <Input
                                                        type="text"
                                                        maxLength="250"
                                                        ref={(input) => {
                                                            this.welcomeInput = input;
                                                        }}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                welcome: e.target.value,
                                                                welcomeState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        onKeyDown={this.handleKeyDown}
                                                        autoCorrect="true"
                                                        value={this.state.welcome}
                                                    />
                                                </FormGroup>
                                                <FormGroup style={{ textAlign: 'end' }}>
                                                    <Label style={{ fontSize: 10, paddingRight: 15 }}>
                                                        {this.state.welcome.length} characters of 250
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ display: this.state.error ? '' : 'none' }}>
                                            <Col xs={12} md={12}>
                                                <FormGroup style={{ textAlign: 'end' }}>
                                                    <Label
                                                        style={{
                                                            fontSize: 14,
                                                            color: 'tomato',
                                                            paddingRight: 15,
                                                            display: this.state.error ? '' : 'none',
                                                        }}
                                                    >
                                                        {this.state.error}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-right">
                                        <Button color="success" onClick={(e) => this.submit(e)}>
                                            {this.state.disabled
                                                ? 'One moment...'
                                                : this.state.companyId
                                                ? 'Update company'
                                                : 'Add company'}
                                        </Button>
                                        {/* spacer between buttons */ ' '}
                                        <Button
                                            style={{ marginLeft: 10 }}
                                            color="secondary"
                                            onClick={() => {
                                                this.props.history.push('/in/l/companies');
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default CompanyForm;
