/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import {
    Input,
    Form,
    FormGroup,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter,
    Row,
    Col,
    Button,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import 'react-dropdown/style.css';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import ReactGA from 'react-ga';

const queryString = require('query-string');

class SubscriberForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            groupId: '',
            disabled: false,
            redirectAfter: '',
            error: '',
            phoneNumber: '',
            phoneNumberState: '',
            name: '',
            nameState: '',
            innerCircle: false,
            privateLabelObject: { id: null, brandName: 'Update Everyone' },
        };
    }

    componentDidMount() {
        //are we logged in?
        //yes
        if (
            localStorage.getItem('LOGGEDIN') &&
            localStorage.getItem('LOGGEDIN') === 'true' &&
            localStorage.getItem('DATEEXPIRES') &&
            new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
        ) {
            ReactGA.pageview(window.location.pathname + window.location.search);

            //grab the querystring
            var qs = queryString.parse(this.props.location.search);

            var privateLabel = JSON.parse(localStorage.getItem('privateLabel'));
            this.setState({
                privateLabelObject: privateLabel !== null ? privateLabel : this.state.privateLabelObject,
            });

            //do we have a group id?
            //yes
            if (qs['id']) {
                //get the group from the server
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        SECRET: localStorage.getItem('SECRET'),
                        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
                    },
                };

                fetch(window.configuration.MemberURL + '?id=' + qs['id'], requestOptions)
                    .then(async (response) => {
                        // check for error response
                        //did we get an error?
                        //yes
                        if (!response.ok) {
                            //set the error
                            this.setState({
                                error: 'We were unable to edit the group',
                                disabled: true,
                                loading: false,
                            });
                        }
                        //did we get an error?
                        //no
                        else {
                            var memberResponse = await response.json();

                            //did we get a good one?
                            //yes
                            if (memberResponse.id) {
                                this.setState({
                                    error: '',
                                    loading: false,
                                    disabled: false,
                                    id: memberResponse.id,
                                    phoneNumber: memberResponse.phoneNumber,
                                    name: memberResponse.name,
                                    innerCircle:
                                        memberResponse.innerCircle === null ? false : memberResponse.innerCircle,
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            error: error,
                            disabled: false,
                            loading: false,
                        });
                    });
            }
            //do we have a group id?
            //no
            else {
                this.setState({ loading: false });
            }

            this.setState({
                groupId: localStorage.getItem('groupId'),
                //set this to tell us where to go after
                redirectAfter: qs['redirectAfter'] ? qs['redirectAfter'] : '',
                loading: false,
            });
        }
        //are we logged in?
        //no
        else {
            this.props.history.push('/a/logout');
        }
    }

    formatPhone(value) {
        // return nothing if no value
        if (!value) {
            return value;
        }

        // only allows 0-9 inputs
        var currentValue = value.replace(/[^\d]/g, '');
        var cvLength = currentValue.length;

        // returns: "x", "xx", "xxx"
        if (cvLength < 4) {
            return currentValue;
        }
        // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
        else if (cvLength < 7) {
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        } else {
            // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    }

    _handleKeyDown = (e) => {
        try {
            //did they press enter?
            //yes
            if (e.key === 'Enter') {
                this.submitForm(null, null);
            }
        } catch (error) {
            console.log(error);
        }
    };

    //this submits the form
    submitForm(e, navigation) {
        //did we disable the button?
        //yes
        if (this.state.disabled) {
            return;
        }

        //do we have a phone number?
        //no
        if (this.state.phoneNumber.length < 10) {
            this.setState({
                error: 'Enter the full number with area code',
            });
            return;
        }

        this.setState({
            disabled: true,
            error: '',
            loading: true,
        });

        // POST request using fetch with error handling
        const requestOptions = {
            method: this.state.id === '' ? 'POST' : 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                SECRET: localStorage.getItem('SECRET'),
                PASSPHRASE: localStorage.getItem('PASSPHRASE'),
            },
            body: JSON.stringify({
                id: this.state.id,
                siteId: window.configuration.siteId,
                groupId: this.state.groupId,
                phoneNumber: this.state.phoneNumber,
                name: this.state.name,
                innerCircle: this.state.innerCircle,
            }),
        };

        fetch(window.configuration.MemberURL, requestOptions)
            .then(async (response) => {
                // check for error response
                //did we get an error?
                //yes
                if (!response.ok) {
                    //did we get an unauthorized?
                    //yes
                    if (response.status === 401) {
                        this.props.history.push('/a/logout');
                    }
                    //did we get an unauthorized?
                    //no
                    else {
                        //set the error
                        this.setState({
                            error: 'We were unable to add the subscriber',
                            disabled: false,
                            loading: false,
                        });
                    }
                }
                //did we get an error?
                //no
                else {
                    var results = await response.json();

                    //did it work?
                    //yes
                    if (results.success === true) {
                        //send them to the subscribers list unless we have a different location
                        this.props.history.push(
                            this.state.redirectAfter ? this.state.redirectAfter : '/in/l/subscribers',
                        );
                        return;
                    }
                    //did it work?
                    //no
                    else {
                        //set the error
                        this.setState({
                            error: results.error,
                            disabled: false,
                            loading: false,
                        });
                    }
                }
            })
            .catch((error) => {
                this.setState({
                    error: error,
                    disabled: false,
                    loading: false,
                });
            });
    }

    render() {
        //are we loading?
        //yes
        if (this.state.loading === true) {
            return (
                <>
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                </>
            );
        }
        return (
            <>
                {this.state.loading ? (
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                ) : (
                    ''
                )}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12} style={{ maxWidth: 700 }}>
                            <Form>
                                <Card>
                                    <CardHeader style={{ marginBottom: 10 }}>
                                        <CardTitle tag="h4">
                                            {this.state.id === '' ? 'Add a subscriber' : 'Update a subscriber'}
                                        </CardTitle>
                                        {this.state.id === ''
                                            ? 'Fill in their cell phone and optional'
                                            : 'Fill in the optional'}{' '}
                                        name of the person you want to add.
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} md={4}>
                                                <FormGroup className={'has-label ' + this.state.phoneNumberState}>
                                                    Cell Phone Number
                                                    <Input
                                                        id={'PhoneNumber'}
                                                        autoFocus={true}
                                                        style={{
                                                            paddingLeft: 20,
                                                            borderColor: 'silver',
                                                            backgroundColor: '#FFFFFF',
                                                            color: '#000000',
                                                            textAlign: 'left',
                                                        }}
                                                        disabled={this.state.id !== ''}
                                                        type="text"
                                                        maxLength="14"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                phoneNumber: this.formatPhone(e.target.value),
                                                                error: '',
                                                            })
                                                        }
                                                        value={this.formatPhone(this.state.phoneNumber)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <FormGroup className={'has-label ' + this.state.nameState}>
                                                    Name
                                                    <Input
                                                        type="text"
                                                        style={{ textTransform: 'capitalize', borderColor: 'silver' }}
                                                        maxLength="100"
                                                        onChange={(e) =>
                                                            this.setState({
                                                                name: e.target.value,
                                                                nameState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        value={this.state.name !== null ? this.state.name : ''}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} style={{ marginTop: 10 }}>
                                                <FormGroup className={'has-label'}>
                                                    <input
                                                        type="checkbox"
                                                        value="Inner Circle"
                                                        checked={this.state.innerCircle}
                                                        id="innerCircle"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                innerCircle: e.target.checked,
                                                                error: '',
                                                            });
                                                        }}
                                                        style={{ marginRight: 10 }}
                                                    />
                                                    <label
                                                        htmlFor={`innerCircle`}
                                                        style={{ color: 'black', fontSize: 14 }}
                                                    >
                                                        This person is part of my inner circle.
                                                    </label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{
                                                display: this.state.error ? '' : 'none',
                                            }}
                                        >
                                            <Col xs={12} md={12}>
                                                <FormGroup
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    {this.state.error}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-right">
                                        <Button
                                            style={{ backgroundColor: this.state.privateLabelObject.successColor }}
                                            onClick={(e) => this.submitForm(e)}
                                        >
                                            {this.state.disabled
                                                ? 'One moment...'
                                                : this.state.id === ''
                                                ? 'Add subscriber'
                                                : 'Update subscriber'}
                                        </Button>
                                        {/* spacer between buttons */ ' '}
                                        <Button
                                            style={{ marginLeft: 10 }}
                                            color="secondary"
                                            onClick={() => {
                                                this.props.history.push(
                                                    this.state.redirectAfter
                                                        ? this.state.redirectAfter
                                                        : '/in/l/subscribers' +
                                                              (!this.state.groupId ? '' : '?id=' + this.state.groupId),
                                                );
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default SubscriberForm;
