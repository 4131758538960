/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import {
    Input,
    Form,
    FormGroup,
    Label,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter,
    Row,
    Col,
    Button,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import 'react-dropdown/style.css';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import ReactGA from 'react-ga';
import Switch from 'react-bootstrap-switch';
import TextareaAutosize from 'react-textarea-autosize';

const queryString = require('query-string');

var companyId = '';

class GroupForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupId: '',
            disabled: false,
            error: '',
            name: '',
            nameState: '',
            description: '',
            descriptionState: '',
            welcome: '',
            open: true,
            loading: true,
            adminPhones: localStorage.getItem('PHONENUMBER'),
        };

        this.reactTags = React.createRef();
    }

    componentDidMount() {
        //are we logged in?
        //yes
        if (
            localStorage.getItem('LOGGEDIN') &&
            localStorage.getItem('LOGGEDIN') === 'true' &&
            localStorage.getItem('DATEEXPIRES') &&
            new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
        ) {
            ReactGA.pageview(window.location.pathname + window.location.search);

            var qs = queryString.parse(this.props.location.search);

            //start it out as the default
            companyId = localStorage.getItem('companyId');

            //do we have a company id coming in on the querystring?
            //yes
            if (qs['companyId']) {
                //set the one from the querystring
                companyId = qs['companyId'];
            }

            //do we have a group id?
            //yes
            if (qs['id']) {
                //get the group from the server
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        SECRET: localStorage.getItem('SECRET'),
                        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
                    },
                };

                fetch(
                    window.configuration.GroupURL +
                        '?id=' +
                        qs['id'] +
                        (companyId !== null ? '&companyId=' + companyId : ''),
                    requestOptions,
                )
                    .then(async (response) => {
                        // check for error response
                        //did we get an error?
                        //yes
                        if (!response.ok) {
                            //set the error
                            this.setState({
                                error: 'We were unable to edit the group',
                                disabled: true,
                                loading: false,
                            });
                        }
                        //did we get an error?
                        //no
                        else {
                            var groupResponse = await response.json();

                            //did we get a good one?
                            //yes
                            if (groupResponse.id) {
                                //send them to the dashboard
                                this.setState({
                                    error: '',
                                    loading: false,
                                    disabled: false,
                                    groupId: groupResponse.id,
                                    name: groupResponse.name,
                                    welcome: groupResponse.welcome ? groupResponse.welcome : '',
                                    open: groupResponse.open,
                                    description: groupResponse.description,
                                    adminPhones: groupResponse.admins.join('\n'),
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            error: error,
                            disabled: false,
                            loading: false,
                        });
                    });
            }
            //do we have a group id?
            //no
            else {
                this.setState({ loading: false });
            }
        }
        //are we logged in?
        //no
        else {
            this.props.history.push('/a/logout');
        }
    }

    _handleKeyDown = (e) => {
        try {
            //did they press enter?
            //yes
            if (e.key === 'Enter') {
                this.submitForm(null, null);
            }
        } catch (error) {
            console.log(error);
        }
    };

    //this submits the form
    submit(e, navigation) {
        //did we disable the button?
        //yes
        if (this.state.disabled) {
            return;
        }

        //do we have a first name?
        //no
        if (this.state.name.length === 0) {
            this.setState({
                error: 'Please enter the name of the group',
            });
            return;
        }

        //do we have a first name?
        //no
        if (this.state.welcome.length <= 10) {
            this.setState({
                error: 'Please enter the welcome message for the group',
            });
            return;
        }

        //do we have a first name?
        //no
        if (this.state.adminPhones.length < 10) {
            this.setState({
                error: 'Please enter a cell phone of someone who can send updates',
            });
            return;
        }

        this.setState({
            disabled: true,
            error: '',
            loading: true,
        });

        // POST request using fetch with error handling
        const requestOptions = {
            method: this.state.groupId === '' ? 'POST' : 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                SECRET: localStorage.getItem('SECRET'),
                PASSPHRASE: localStorage.getItem('PASSPHRASE'),
            },
            body: JSON.stringify({
                id: this.state.groupId,
                siteId: window.configuration.siteId,
                companyId: companyId !== null && companyId !== 'null' ? companyId : null,
                open: this.state.open,
                name: this.state.name,
                description: this.state.description,
                welcome: this.state.welcome,
                admins: this.state.adminPhones.split('\n'),
            }),
        };

        fetch(window.configuration.GroupURL, requestOptions)
            .then(async (response) => {
                // check for error response
                //did we get an error?
                //yes
                if (!response.ok) {
                    //did we get an unauthorized?
                    //yes
                    if (response.status === 401) {
                        this.props.history.push('/a/logout');
                    }
                    //did we get an unauthorized?
                    //no
                    else {
                        //set the error
                        this.setState({
                            error: 'We were unable to add the group',
                            disabled: false,
                            loading: false,
                        });
                    }
                }
                //did we get an error?
                //no
                else {
                    var qs = queryString.parse(this.props.location.search);

                    //send them to the dashboard
                    this.props.history.push(
                        '/in/l/groups?cancel=false' +
                            (qs['returnAfter'] !== undefined && qs['returnAfter'] && qs['returnAfter'].length > 0
                                ? '&returnAfter=' + qs['returnAfter']
                                : '') +
                            (companyId !== null && companyId !== 'null' ? '&companyId=' + companyId : ''),
                    );
                }
            })
            .catch((error) => {
                this.setState({
                    error: error,
                    disabled: false,
                    loading: false,
                });
            });
    }

    render() {
        var qs = queryString.parse(this.props.location.search);

        return (
            <>
                {this.state.loading ? (
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                ) : (
                    ''
                )}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12} style={{ maxWidth: 900 }}>
                            <Form>
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">
                                            {this.state.groupId
                                                ? 'Update your Update Everyone group'
                                                : 'Add a new Update Everyone group'}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} md={5}>
                                                <FormGroup className={'has-label ' + this.state.nameState}>
                                                    <Label>Group Name *</Label>
                                                    <Input
                                                        type="text"
                                                        style={{ textTransform: 'capitalize' }}
                                                        maxLength="250"
                                                        ref={(input) => {
                                                            this.nameInput = input;
                                                        }}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                name: e.target.value,
                                                                nameState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        onKeyDown={this.handleKeyDown}
                                                        autoFocus
                                                        autoCorrect="true"
                                                        value={this.state.name}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={7}>
                                                <FormGroup className={'has-label ' + this.state.descriptionState}>
                                                    <Label>Group Description</Label>
                                                    <Input
                                                        type="text"
                                                        maxLength="1000"
                                                        ref={(input) => {
                                                            this.descriptionInput = input;
                                                        }}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                description: e.target.value,
                                                                descriptionState:
                                                                    e.target.value.length > 0
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        onKeyDown={this.handleKeyDown}
                                                        autoCorrect="true"
                                                        value={this.state.description}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FormGroup className={'has-label'}>
                                                    <Label>Welcome message to send when someone subscribes *</Label>
                                                    <TextareaAutosize
                                                        style={{ width: '100%', padding: 10, borderColor: 'silver' }}
                                                        minRows={2}
                                                        maxRows={3}
                                                        className="react-select"
                                                        type="text"
                                                        maxLength="250"
                                                        ref={(input) => {
                                                            this.textInput = input;
                                                        }}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                welcome: e.target.value,
                                                                welcomeState:
                                                                    e.target.value.length > 4
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        autoCorrect="true"
                                                        value={this.state.welcome}
                                                    />
                                                </FormGroup>
                                                <FormGroup style={{ textAlign: 'end' }}>
                                                    <Label style={{ fontSize: 10, paddingRight: 15 }}>
                                                        {this.state.welcome.length} characters of 250
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FormGroup className={'has-label'}>
                                                    <Label>
                                                        Cell phones numbers of people who can send updates (one per
                                                        line) *
                                                    </Label>
                                                    <Input
                                                        type="textarea"
                                                        maxLength="200"
                                                        style={{
                                                            overflow: 'hidden',
                                                            minHeight: 50,
                                                            border: '1px solid #eee',
                                                            maxHeight: 500,
                                                            height:
                                                                25 *
                                                                    (this.state.adminPhones.match(/\n/g) || []).length +
                                                                40,
                                                        }}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                adminPhones: e.target.value,
                                                                error: '',
                                                            });
                                                        }}
                                                        value={this.state.adminPhones}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={5}>
                                                <Label>Group is open to anyone to subscribe via text</Label>
                                                <FormGroup className={'has-label'}>
                                                    <Switch
                                                        onColor="green"
                                                        labelWidth="auto"
                                                        handleWidth="auto"
                                                        onText="Yes"
                                                        offText="No"
                                                        defaultValue={this.state.open}
                                                        value={this.state.open}
                                                        onChange={(e) => {
                                                            this.setState({ open: e.state.value });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ display: this.state.error ? '' : 'none' }}>
                                            <Col xs={12} md={12}>
                                                <FormGroup style={{ textAlign: 'end' }}>
                                                    <Label
                                                        style={{
                                                            fontSize: 14,
                                                            color: 'tomato',
                                                            paddingRight: 15,
                                                            display: this.state.error ? '' : 'none',
                                                        }}
                                                    >
                                                        {this.state.error}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-right">
                                        <Button color="success" onClick={(e) => this.submit(e)}>
                                            {this.state.disabled
                                                ? 'One moment...'
                                                : this.state.groupId
                                                ? 'Update group'
                                                : 'Add group'}
                                        </Button>
                                        {/* spacer between buttons */ ' '}
                                        <Button
                                            style={{ marginLeft: 10 }}
                                            color="secondary"
                                            onClick={() => {
                                                this.props.history.push(
                                                    '/in/l/groups?cancel=true' +
                                                        (companyId !== null && companyId !== 'null'
                                                            ? '&companyId=' + companyId
                                                            : '') +
                                                        (qs['returnAfter'] && qs['returnAfter'].length > 0
                                                            ? '&returnAfter=' + qs['returnAfter']
                                                            : ''),
                                                );
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default GroupForm;
