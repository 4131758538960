/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import {
    Form,
    FormGroup,
    Label,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter,
    Row,
    Col,
    Button,
} from 'reactstrap';
// react plugin used to create datetimepicker
import Datetime from 'react-datetime';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import ReactGA from 'react-ga';
import TextareaAutosize from 'react-textarea-autosize';
import moment from 'moment';

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';

const queryString = require('query-string');

class UpdateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            redirectAfter: '',
            error: '',
            id: '',
            text: '',
            textState: '',
            dateStart: Datetime.moment(),
            dateStartState: 'has-success',
            smsPhoneNumber: '',
            innerCircle: false,
            loading: true,
            privateLabelObject: { id: null, brandName: 'Update Everyone' },
        };
    }

    componentDidMount() {
        //are we logged in?
        //yes
        if (
            localStorage.getItem('LOGGEDIN') &&
            localStorage.getItem('LOGGEDIN') === 'true' &&
            localStorage.getItem('smsPhoneNumber') &&
            localStorage.getItem('DATEEXPIRES') &&
            new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
        ) {
            ReactGA.pageview(window.location.pathname + window.location.search);

            this.setState({
                smsPhoneNumber:
                    '(' +
                    localStorage.getItem('smsPhoneNumber').slice(0, 3) +
                    ') ' +
                    localStorage.getItem('smsPhoneNumber').slice(3, 6) +
                    '-' +
                    localStorage.getItem('smsPhoneNumber').slice(6, 10),
            });

            var qs = queryString.parse(this.props.location.search);

            var privateLabel = JSON.parse(localStorage.getItem('privateLabel'));
            this.setState({
                privateLabelObject: privateLabel !== null ? privateLabel : this.state.privateLabelObject,
            });

            //is this their first time?
            //yes
            if (qs['first'] === 'true') {
                //set it for tomorrow
                this.setState({ dateStart: Datetime.moment().add(1, 'days') });
            }

            //do we need to set the redirect after?
            //yes
            if (qs['redirectAfter'] && !this.state.redirectAfter) {
                this.setState({ redirectAfter: qs['redirectAfter'] });
            }

            //do we have an update id?
            //yes
            if (qs['id']) {
                //get our record from the system
                //GET request using fetch with error handling
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        SECRET: localStorage.getItem('SECRET'),
                        PASSPHRASE: localStorage.getItem('PASSPHRASE'),
                    },
                };

                fetch(window.configuration.AnnouncementURL + '?id=' + qs['id'], requestOptions).then(
                    async (response) => {
                        //did we get an error?
                        //yes
                        if (!response.ok) {
                            //did we get an unauthorized?
                            //yes
                            if (response.status === 401) {
                                this.props.history.push('/a/logout');
                            }
                            //did we get an unauthorized?
                            //no
                            else {
                                //set the error
                                this.props.history.push('/in/l/updates');
                            }
                        }
                        //did we get an error?
                        //no
                        else {
                            //grab our response
                            var announcement = await response.json();

                            var startdate = moment(new Date(announcement.dateStart)).format('MM/DD/YYYY hh:mm A');
                            //does it have the right format?
                            //no
                            if (!startdate.startsWith('1') && !startdate.startsWith('0')) {
                                startdate = '0' + startdate;
                            }

                            this.setState({
                                id: announcement.id,
                                text: announcement.text,
                                textState: 'has-success',
                                dateStart: startdate,
                                innerCircle: announcement.innerCircle === null ? false : announcement.innerCircle,
                                loading: false,
                            });
                        }
                    },
                );
            }
            //do we have a update id?
            //no
            else {
                this.setState({
                    loading: false,
                });
            }
        }
        //are we logged in?
        //no
        else {
            this.props.history.push('/a/logout');
        }
    }

    _handleKeyDown = (e) => {
        try {
            //did they press enter?
            //yes
            if (e.key === 'Enter') {
                this.submitForm(null, null);
            }
        } catch (error) {
            console.log(error);
        }
    };

    //this submits the update
    submitForm(e, startDateValue) {
        //did we disable the button?
        //yes
        if (this.state.disabled) {
            return;
        }
        this.setState({
            disabled: true,
            error: '',
            loading: true,
        });

        //do we have an update with at least 7 characters?
        //no
        if (this.state.text.length < 7) {
            this.setState({
                error: 'Update is too short. Enter at least 7 characters.',
                disabled: false,
                loading: false,
            });
            return;
        }
        //do we have an update with at least 7 characters?
        //yes
        else {
            // POST request using fetch with error handling
            const requestOptions = {
                method: this.state.id === '' ? 'POST' : 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    SECRET: localStorage.getItem('SECRET'),
                    PASSPHRASE: localStorage.getItem('PASSPHRASE'),
                },
                body: JSON.stringify({
                    id: this.state.id,
                    siteId: window.configuration.siteId,
                    employeeId: localStorage.getItem('employeeId'),
                    text: this.state.text,
                    dateStart: startDateValue,
                    innerCircle: this.state.innerCircle,
                }),
            };

            fetch(window.configuration.AnnouncementURL, requestOptions)
                .then(async (response) => {
                    // check for error response
                    //did we get an error?
                    //yes
                    if (!response.ok) {
                        //set the error
                        this.setState({
                            error: 'We were unable to add the update',
                            disabled: false,
                            loading: false,
                        });
                    }
                    //did we get an error?
                    //no
                    else {
                        var updateResponse = await response.json();

                        //did it work?
                        //yes
                        if (updateResponse.error === null) {
                            //send them to the updates
                            this.props.history.push(
                                this.state.redirectAfter ? this.state.redirectAfter : '/in/l/updates',
                            );
                        }
                        //did it work?
                        //no
                        else {
                            //set the error
                            this.setState({
                                error: updateResponse.error,
                                disabled: false,
                                loading: false,
                            });
                        }
                    }
                })
                .catch((error) => {
                    this.setState({
                        error: error,
                        disabled: false,
                        loading: false,
                    });
                });
        }
    }

    render() {
        var yesterday = (function (d) {
            d.setDate(d.getDate() - 1);
            return d;
        })(new Date());

        //is it going to go for at least one day?
        //yes
        var validStartDate = function (current) {
            return current.isAfter(yesterday);
        };

        var qs = queryString.parse(this.props.location.search);

        return (
            <>
                {this.state.loading ? (
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                ) : (
                    ''
                )}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12} style={{ maxWidth: 900 }}>
                            <Form>
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">
                                            Update your subscribers from {this.state.smsPhoneNumber}
                                        </CardTitle>
                                        {qs['first'] === 'true'
                                            ? 'Get ready to send your first update! We scheduled it for tomorrow to give your friends and family a chance to subscribe.'
                                            : 'You can schedule a text message update to your subscribers by filling out the form below or your can send a text message to ' +
                                              this.state.smsPhoneNumber +
                                              ' and the system will send it out.'}
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FormGroup className={'has-label ' + this.state.textState}>
                                                    What update would you like to send? *
                                                    <br />
                                                    <TextareaAutosize
                                                        style={{
                                                            width: '100%',
                                                            padding: 10,
                                                            borderColor: 'silver',
                                                        }}
                                                        minRows={2}
                                                        maxRows={30}
                                                        className="react-select"
                                                        type="text"
                                                        maxLength="5000"
                                                        ref={(input) => {
                                                            this.textInput = input;
                                                        }}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                text: e.target.value,
                                                                textState:
                                                                    e.target.value.length > 4
                                                                        ? 'has-success'
                                                                        : 'has-danger',
                                                                error: '',
                                                            })
                                                        }
                                                        autoFocus
                                                        autoCorrect="true"
                                                        value={this.state.text}
                                                    />
                                                </FormGroup>
                                                <FormGroup style={{ textAlign: 'end', marginTop: -10 }}>
                                                    <Label style={{ fontSize: 10, paddingRight: 5 }}>
                                                        {this.state.text.length} characters
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} style={{ marginTop: -20 }}>
                                                <FormGroup className={'has-label'}>
                                                    <input
                                                        type="checkbox"
                                                        value="Inner Circle"
                                                        checked={this.state.innerCircle}
                                                        id="innerCircle"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                innerCircle: e.target.checked,
                                                                error: '',
                                                            });
                                                        }}
                                                        style={{ marginRight: 10 }}
                                                    />
                                                    <label
                                                        htmlFor={`innerCircle`}
                                                        style={{ color: 'black', fontSize: 14 }}
                                                    >
                                                        Only send this to subscribers marked as "inner circle"
                                                    </label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ paddingTop: 10 }}>
                                            <Col xs={12} md={5}>
                                                <FormGroup className={'has-label ' + this.state.dateStartState}>
                                                    When would you like to send this update? *
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        closeOnTab={true}
                                                        defaultValue={this.state.dateStart}
                                                        isValidDate={validStartDate}
                                                        timeFormat={true}
                                                        onChange={(e) => {
                                                            if (
                                                                e._isAMomentObject &&
                                                                e._isAMomentObject === true &&
                                                                e._isValid &&
                                                                e._isValid === true
                                                            ) {
                                                                this.setState({
                                                                    dateStart: e,
                                                                    dateStartState:
                                                                        e.format('MM/DD/YYYY').length > 0
                                                                            ? 'has-success'
                                                                            : 'has-danger',
                                                                    error: '',
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    dateStart: e,
                                                                    dateStartState:
                                                                        e.length > 0 ? 'has-success' : 'has-danger',
                                                                    error: '',
                                                                });
                                                            }
                                                        }}
                                                        value={this.state.dateStart}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FormGroup
                                                    style={{ textAlign: 'end', display: this.state.error ? '' : '' }}
                                                >
                                                    <Label
                                                        style={{
                                                            fontSize: 14,
                                                            color: 'tomato',
                                                            paddingTop: 20,
                                                            paddingRight: 15,
                                                            display: this.state.error ? '' : 'none',
                                                        }}
                                                    >
                                                        {this.state.error.toString()}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-right">
                                        <Button
                                            style={{ backgroundColor: this.state.privateLabelObject.successColor }}
                                            onClick={(e) => {
                                                this.submitForm(e, this.state.dateStart);
                                            }}
                                        >
                                            {this.state.disabled
                                                ? 'One moment...'
                                                : this.state.id
                                                ? 'Modify update'
                                                : 'Schedule update'}
                                        </Button>
                                        {/* spacer between buttons */ ' '}
                                        <Button
                                            style={{
                                                backgroundColor: this.state.privateLabelObject.successColor,
                                                marginLeft: 10,
                                            }}
                                            onClick={(e) => this.submitForm(e, null)}
                                        >
                                            {this.state.disabled ? 'One moment...' : 'Send now'}
                                        </Button>
                                        {/* spacer between buttons */ ' '}
                                        <Button
                                            style={{
                                                backgroundColor: this.state.privateLabelObject.failureColor,
                                                marginLeft: 10,
                                            }}
                                            color="secondary"
                                            onClick={() => {
                                                this.props.history.push(
                                                    this.state.redirectAfter
                                                        ? this.state.redirectAfter
                                                        : '/in/l/updates',
                                                );
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default UpdateForm;
