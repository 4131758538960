/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/*eslint-disable*/
import React from 'react';
// used for making the prop types of this component
import PropTypes from 'prop-types';

class Footer extends React.Component {
    render() {
        return (
            <footer className={'footer' + (this.props.default ? ' footer-default' : '')}>
                <div className="copyright" style={{ color: 'black', marginTop: -30, marginLeft: 15 }}>
                    &copy; 2021 Update Everyone, Inc. - all rights reserved y'all
                </div>
            </footer>
        );
    }
}

Footer.defaultProps = {
    default: false,
    fluid: false,
};

Footer.propTypes = {
    default: PropTypes.bool,
    fluid: PropTypes.bool,
};

export default Footer;
