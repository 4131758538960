/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/*eslint-disable*/
import React, { Component } from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';
import { Label, Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactGA from 'react-ga';
import moment from 'moment';
import Select from 'react-select';

class ResponseList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            filtered: [],
            groupId: localStorage.getItem('groupId'),
            timing: [
                {
                    value: 'last 7 days',
                    label: 'Show the last 7 days',
                    startDate: moment().subtract(7, 'days').utc().format('MM-DD-YYYY'),
                    endDate: moment().add(1, 'days').utc().format('MM-DD-YYYY'),
                },
                {
                    value: 'last 2 weeks',
                    label: 'Show the last 2 weeks',
                    startDate: moment().subtract(14, 'days').utc().format('MM-DD-YYYY'),
                    endDate: moment().add(1, 'days').utc().format('MM-DD-YYYY'),
                },
                {
                    value: 'last 30 days',
                    label: 'Show the last 30 days',
                    startDate: moment().subtract(30, 'days').utc().format('MM-DD-YYYY'),
                    endDate: moment().add(1, 'days').utc().format('MM-DD-YYYY'),
                },
                {
                    value: 'everything',
                    label: 'Show everything',
                    startDate: moment().subtract(2, 'years').utc().format('MM-DD-YYYY'),
                    endDate: moment().add(1, 'days').utc().format('MM-DD-YYYY'),
                },
            ],
            selectedTiming: {
                value: 'last 7 days',
                label: 'Show the last 7 days',
                startDate: moment().subtract(7, 'days').utc().format('MM-DD-YYYY'),
                endDate: moment().add(1, 'days').utc().format('MM-DD-YYYY'),
            },
            privateLabelObject: { id: null, brandName: 'Update Everyone' },
        };
    }

    //we get the data from the system on this call
    componentDidMount() {
        //are we logged in?
        //yes
        if (
            localStorage.getItem('LOGGEDIN') &&
            localStorage.getItem('LOGGEDIN') === 'true' &&
            localStorage.getItem('DATEEXPIRES') &&
            new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
        ) {
            ReactGA.pageview(window.location.pathname + window.location.search);

            var privateLabel = JSON.parse(localStorage.getItem('privateLabel'));
            this.setState({
                privateLabelObject: privateLabel !== null ? privateLabel : this.state.privateLabelObject,
            });

            this.loadData(this.state.selectedTiming);
        }
        //are we logged in?
        //no
        else {
            this.props.history.push('/a/logout');
        }
    }

    loadData(selectedTiming) {
        // GET request using fetch with error handling
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SECRET: localStorage.getItem('SECRET'),
                PASSPHRASE: localStorage.getItem('PASSPHRASE'),
            },
        };

        fetch(
            window.configuration.TextResponseURL +
                '?startDate=' +
                selectedTiming.startDate +
                '&endDate=' +
                selectedTiming.endDate,
            requestOptions,
        )
            .then(async (response) => {
                //did we get an error?
                //yes
                if (!response.ok) {
                    //did we get an unauthorized?
                    //yes
                    if (response.status === 401) {
                        this.props.history.push('/a/logout');
                    }
                    return null;
                }
                //did we get an error?
                //no
                else {
                    var responses = await response.json();

                    //create our rows
                    var responseObjects = responses.map(function (textResponse) {
                        return {
                            id: textResponse.id,
                            dateAdded: moment(
                                new Date(textResponse.dateAdded).toLocaleDateString() +
                                    ' ' +
                                    new Date(textResponse.dateAdded).toLocaleTimeString(),
                            ).format('MM/DD @ h:mm a'),
                            text: textResponse.text,
                            from: textResponse.name
                                ? textResponse.name
                                : '(' +
                                  textResponse.fromPhoneNumber.slice(0, 3) +
                                  ') ' +
                                  textResponse.fromPhoneNumber.slice(3, 6) +
                                  '-' +
                                  textResponse.fromPhoneNumber.slice(6, 10),
                        };
                    });

                    //grab our response
                    this.setState({
                        data: responseObjects,
                        loading: false,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    error: error,
                    loading: false,
                });
                return null;
            });
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter['id'] === accessor) {
                    if (value === '' || !value.length) filtered.splice(i, 1);
                    else filter['value'] = value;

                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    };

    render() {
        if (this.state.loading === true) {
            return (
                <>
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                </>
            );
        }

        return (
            <>
                {this.state.loading ? (
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                ) : (
                    ''
                )}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12} style={{ maxWidth: 1000 }}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Subscriber Replies</CardTitle>
                                    <CardTitle>
                                        Here are the replies that have been sent from your subscribers.
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row style={{ marginTop: 0, marginBottom: 20 }}>
                                        <Col xs={12} md={3}>
                                            <Select
                                                className="react-select"
                                                options={this.state.timing}
                                                onChange={(selectedTiming) => {
                                                    this.setState({
                                                        selectedTiming: selectedTiming,
                                                        loading: true,
                                                    });
                                                    this.loadData(selectedTiming);
                                                }}
                                                value={this.state.selectedTiming}
                                            />
                                        </Col>
                                    </Row>
                                    <ReactTable
                                        data={this.state.data}
                                        filterable={true}
                                        filtered={this.state.filtered}
                                        onFilteredChange={(filtered, column, value) => {
                                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                                        }}
                                        defaultFilterMethod={(filter, row, column) => {
                                            const id = filter.pivotId || filter.id;
                                            if (typeof filter.value === 'object') {
                                                return row[id] !== undefined
                                                    ? filter.value.indexOf(row[id]) > -1
                                                    : true;
                                            } else {
                                                return row[id] !== undefined
                                                    ? String(row[id]).indexOf(filter.value) > -1
                                                    : true;
                                            }
                                        }}
                                        columns={[
                                            {
                                                Header: 'Received',
                                                accessor: 'dateAdded',
                                                width: 150,
                                                //sort the dates
                                                sortMethod: (a, b) => {
                                                    a = new Date(a).getTime();
                                                    b = new Date(b).getTime();
                                                    return b > a ? 1 : -1;
                                                },
                                                filterable: false,
                                            },
                                            {
                                                Header: 'From',
                                                accessor: 'from',
                                                width: 150,
                                                sortable: true,
                                                filterable: false,
                                            },
                                            {
                                                Header: '',
                                                accessor: 'text',
                                                sortable: false,
                                                style: { whiteSpace: 'unset' },
                                                filterable: false,
                                            },
                                        ]}
                                        defaultPageSize={this.state.data.length}
                                        showPaginationBottom={false}
                                        className="-striped -highlight"
                                    />
                                    <Label
                                        style={{
                                            fontSize: 14,
                                            marginTop: 10,
                                            paddingLeft: 5,
                                            display: this.state.data.length === 0 ? '' : 'none',
                                        }}
                                    >
                                        No replies
                                        <br />
                                        <br />
                                    </Label>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default ResponseList;
