/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';
import {
    CardHeader,
    Card,
    CardBody,
    CardFooter,
    Form,
    Container,
    Col,
    InputGroup,
    Button,
    Label,
    Input,
} from 'reactstrap';
import 'react-phone-input-2/lib/style.css';
import bgImage from 'assets/img/background.png';
import logoImage from 'assets/img/logo.png';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import ReactGA from 'react-ga';

const queryString = require('query-string');

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            phoneNumber: '',
            phoneSubmitted: false,
            hidePhone: false,
            code: '',
            hideCode: true,
            error: '',
            pageLoaded: false,
            type: 0,
            loading: false,
            hideGroups: true,
            groups: [],
            SECRET: '',
            PASSPHRASE: '',
            DATEEXPIRES: '',
            gotAuth: false,
        };
    }

    formatPhone(value) {
        // return nothing if no value
        if (!value) {
            return value;
        }

        // only allows 0-9 inputs
        var currentValue = value.replace(/[^\d]/g, '');
        var cvLength = currentValue.length;

        // returns: "x", "xx", "xxx"
        if (cvLength < 4) {
            return currentValue;
        }
        // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
        else if (cvLength < 7) {
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        } else {
            // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    }

    componentDidUpdate() {
        if (this.state.gotAuth === false) {
            console.log('getting auth');
            //get the auth stuff (secret and passphrase)
            this.getAuth();
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);

        //did they refresh the page and lose our stuff?
        //yes
        if (this.state.phoneNumber && !localStorage.getItem('SECRET')) {
            this.props.history.push('/a/login');
        }
        //did they refresh the page and lose our stuff?
        //no
        else {
            document.body.classList.add('login-page');
        }
        this.getAuth();
    }

    componentWillUnmount() {
        try {
            document.body.classList.remove('login-page');
        } catch (err) {
            console.log(err);
        }
    }

    codeUpdated(newCode) {
        //do we have 3 or less?
        //yes
        if (newCode.length <= 3) {
            this.setState(
                {
                    code: newCode,
                },
                () => {
                    //do we have our 3?
                    //yes
                    if (newCode.length === 3) {
                        this.submitForm(null);
                    }
                },
            );
        }
    }

    //this gets our secret and passphrase
    getAuth() {
        console.log('state', this.state);
        //have we already got the auth?
        //yes
        if (this.state.gotAuth === true) {
            //no need to get it again
            return;
        }

        // POST request using fetch with error handling
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        fetch(window.configuration.AuthURL, requestOptions)
            .then(async (response) => {
                //did we get an error?
                //yes
                if (!response.ok) {
                    //set the error
                    this.setState({
                        error: 'An error occurred. Refresh page.',
                        disabled: false,
                        loading: false,
                    });
                }
                //did we get an error?
                //no
                else {
                    //grab our response
                    var authResponse = await response.json();

                    //do we have an auth response?
                    //yes
                    if (authResponse) {
                        localStorage.setItem('SECRET', authResponse.secret);
                        localStorage.setItem('PASSPHRASE', authResponse.passPhrase);
                        localStorage.setItem('DATEEXPIRES', authResponse.dateExpires);
                        localStorage.setItem('PHONENUMBER', this.state.phoneNumber);

                        //set the state information
                        this.setState({
                            SECRET: authResponse.secret,
                            PASSPHRASE: authResponse.passPhrase,
                            DATEEXPIRES: authResponse.dateExpires,
                            gotAuth: true,
                        });
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false,
                    loading: false,
                });
            });
    }

    //this submits the phone number or the code
    submitForm(groupId) {
        try {
            //did we disable the button or need to wait?
            //yes
            if (
                this.state.disabled ||
                this.state.phoneNumber === '' ||
                (this.state.phoneSubmitted === true && this.state.code.length < 3)
            ) {
                console.log('returning', this.state.code.length);
                return;
            }

            //is this disabled?
            //yes
            if (this.state.disabled === false) {
                //enable it
                this.setState({
                    disabled: true,
                    loading: true,
                });
            }

            var qs = queryString.parse(this.props.location.search);

            // POST request using fetch with error handling
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    SECRET: this.state.SECRET,
                    PASSPHRASE: this.state.PASSPHRASE,
                },
                body: JSON.stringify({
                    phoneNumber: this.state.phoneNumber,
                    code: this.state.code,
                    overridePhone: qs['override'],
                    groupId: groupId,
                    siteId: window.configuration.siteId,
                }),
            };

            fetch(window.configuration.SMSAuthURL, requestOptions)
                .then(async (response) => {
                    //did we get an error?
                    //yes
                    if (!response.ok) {
                        //set the error
                        this.setState({
                            error: 'Unable to verify your phone number. Try again.',
                            loading: false,
                            disabled: false,
                        });
                    }
                    //did we get an error?
                    //no
                    else {
                        //grab our response
                        var smsAuthResponse = await response.json();

                        //did we get a good response?
                        //no
                        if (smsAuthResponse.success === false) {
                            //hide the phone number and show the code field
                            this.setState({
                                error: smsAuthResponse.error,
                                disabled: false,
                                loading: false,
                            });
                        }
                        //did we get a good response?
                        //yes
                        else if (smsAuthResponse.success === true) {
                            //do we have groups coming back?
                            //yes
                            if (smsAuthResponse.groups !== null && smsAuthResponse.groups !== '') {
                                let buttons = [];
                                //iterate through the groups
                                for (var i = 0; i < smsAuthResponse.groups.length; i++) {
                                    const groupItem = smsAuthResponse.groups[i];
                                    buttons.push(
                                        <Button
                                            size="lg"
                                            className="mb-3 btn-round"
                                            style={{
                                                marginLeft: 0,
                                                marginBottom: 5,
                                                width: 300,
                                                backgroundColor: '#52CBD9',
                                            }}
                                            key={groupItem.id}
                                            onClick={(e) => {
                                                this.submitForm(groupItem.id);
                                            }}
                                        >
                                            {groupItem.name}
                                        </Button>,
                                    );
                                }
                                this.setState({
                                    hidePhone: true,
                                    hideCode: true,
                                    hideGroups: false, //show the groups
                                    disabled: false,
                                    loading: false,
                                    error: '',
                                    groups: buttons,
                                });
                            }
                            //did we get a good employee or member back yet?
                            //no
                            else if (!smsAuthResponse.employeeId && !smsAuthResponse.phoneNumber) {
                                //hide the phone number and show the code field
                                this.setState({
                                    hidePhone: true,
                                    hideCode: false, //show the code
                                    hideGroups: true,
                                    disabled: false,
                                    phoneSubmitted: true,
                                    loading: false,
                                    error: '',
                                });
                            }
                            //did we get a good employee or member back yet?
                            //yes
                            else {
                                //do we have a cc?
                                //yes
                                if (smsAuthResponse.ccLast4) {
                                    localStorage.setItem('ccLast4', smsAuthResponse.ccLast4);
                                }
                                //do we have a cc?
                                //no
                                else {
                                    localStorage.setItem('ccLast4', '');
                                }

                                //do we have a cc?
                                //yes
                                if (smsAuthResponse.ccExpirationDate) {
                                    localStorage.setItem('ccExpirationDate', smsAuthResponse.ccExpirationDate);
                                }
                                //do we have a cc?
                                //no
                                else {
                                    localStorage.setItem('ccExpirationDate', '');
                                }

                                //do we have a promotional account?
                                //yes
                                if (smsAuthResponse.promotional) {
                                    localStorage.setItem('discountTier0', smsAuthResponse.promotional.discountTier0);
                                }
                                //do we have a promotional account?
                                //no
                                else {
                                    localStorage.setItem('discountTier0', 0.0);
                                }

                                //do we have a promotional account?
                                //yes
                                if (smsAuthResponse.promotional) {
                                    localStorage.setItem('discountTier1', smsAuthResponse.promotional.discountTier1);
                                }
                                //do we have a promotional account?
                                //no
                                else {
                                    localStorage.setItem('discountTier1', 0.0);
                                }

                                //do we have a promotional account?
                                //yes
                                if (smsAuthResponse.promotional) {
                                    localStorage.setItem('discountTier2', smsAuthResponse.promotional.discountTier2);
                                }
                                //do we have a promotional account?
                                //no
                                else {
                                    localStorage.setItem('discountTier2', 0.0);
                                }

                                //do we have a promotional account?
                                //yes
                                if (smsAuthResponse.promotional) {
                                    localStorage.setItem('discountTier3', smsAuthResponse.promotional.discountTier3);
                                }
                                //do we have a promotional account?
                                //no
                                else {
                                    localStorage.setItem('discountTier3', 0.0);
                                }

                                //do we have a groupAdmin flag?
                                //yes
                                if (smsAuthResponse.groupAdminFlag && smsAuthResponse.groupAdminFlag === true) {
                                    localStorage.setItem('groupAdminFlag', smsAuthResponse.groupAdminFlag);
                                }
                                //do we have a member flag?
                                //no
                                else {
                                    localStorage.removeItem('groupAdminFlag');
                                }

                                //do we have an systemAdmin Flag?
                                //yes
                                if (smsAuthResponse.systemAdminFlag && smsAuthResponse.systemAdminFlag === true) {
                                    localStorage.setItem('systemAdminFlag', smsAuthResponse.systemAdminFlag);
                                }
                                //do we have an adminFlag?
                                //no
                                else {
                                    localStorage.removeItem('systemAdminFlag');
                                }

                                //do we have a company id?
                                //yes
                                if (smsAuthResponse.companyId) {
                                    localStorage.setItem('companyId', smsAuthResponse.companyId);
                                }
                                //do we have a company id?
                                //no
                                else {
                                    localStorage.removeItem('companyId');
                                }

                                //do we have a company name?
                                //yes
                                if (smsAuthResponse.companyName) {
                                    localStorage.setItem('companyName', smsAuthResponse.companyName);
                                }
                                //do we have a company id?
                                //no
                                else {
                                    localStorage.removeItem('companyName');
                                }

                                //do we have a group id?
                                //yes
                                if (smsAuthResponse.groupId) {
                                    localStorage.setItem('groupId', smsAuthResponse.groupId);
                                }
                                //do we have a group id?
                                //no
                                else {
                                    localStorage.removeItem('groupId');
                                }

                                //do we have a smsPhoneNumber?
                                //yes
                                if (smsAuthResponse.smsPhoneNumber) {
                                    localStorage.setItem('smsPhoneNumber', smsAuthResponse.smsPhoneNumber);
                                }
                                //do we have a smsPhoneNumber?
                                //no
                                else {
                                    localStorage.removeItem('smsPhoneNumber');
                                }

                                //do we have an employee id?
                                //yes
                                if (smsAuthResponse.employeeId) {
                                    localStorage.setItem('employeeId', smsAuthResponse.employeeId);
                                }
                                //do we have an employee id?
                                //no
                                else {
                                    localStorage.removeItem('employeeId');
                                }

                                //do we have a firstName?
                                //yes
                                if (smsAuthResponse.firstName) {
                                    localStorage.setItem('firstName', smsAuthResponse.firstName);
                                }
                                //do we have a firstName?
                                //no
                                else {
                                    localStorage.removeItem('firstName');
                                }

                                //do we have a lastName?
                                //yes
                                if (smsAuthResponse.lastName) {
                                    localStorage.setItem('lastName', smsAuthResponse.lastName);
                                }
                                //do we have a lastName?
                                //no
                                else {
                                    localStorage.removeItem('lastName');
                                }

                                //do we have a private label?
                                //yes
                                if (smsAuthResponse.privateLabel) {
                                    localStorage.setItem('privateLabel', JSON.stringify(smsAuthResponse.privateLabel));
                                }
                                //do we have a private label?
                                //no
                                else {
                                    localStorage.removeItem('privateLabel');
                                }

                                //do we have a consumption-based plan?
                                //yes
                                if (smsAuthResponse.consumptionBased) {
                                    localStorage.setItem('consumptionBased', smsAuthResponse.consumptionBased);
                                }
                                //do we have a consumption-based plan?
                                //no
                                else {
                                    localStorage.setItem('consumptionBased', 'false');
                                }

                                localStorage.setItem('LOGGEDIN', 'true');
                                localStorage.setItem('PHONENUMBER', this.state.phoneNumber);

                                //send them to the first time page
                                this.props.history.push('/in/f/dashboard');
                            }
                        }
                        //did we get a good response?
                        //no
                        else {
                            //have we entered a code yet?
                            //no
                            if (!this.state.code) {
                                //set the error
                                this.setState({
                                    error: 'Unable to find that phone number. Try again.',
                                    disabled: false,
                                    loading: false,
                                });
                            }
                            //have we entered a code yet?
                            //yes
                            else {
                                //set the error
                                this.setState({
                                    error: 'Unable to match the code to the phone number. Try again.',
                                    disabled: false,
                                    loading: false,
                                });
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        error: 'Problem finding your account. Please try again.',
                        disabled: false,
                        loading: false,
                    });
                });
        } catch (err) {
            console.log(err);
            this.setState({
                error: 'Problem finding your account. Try again.',
                disabled: false,
                loading: false,
            });
        }

        //did we get an error?
        //yes
        if (this.state.error !== '') {
            this.submitForm(groupId);
        }
    }

    _handleKeyDown = (e) => {
        try {
            //did they press enter?
            //yes
            if (e && e.key === 'Enter') {
                this.submitForm(null);
            }
        } catch (error) {
            console.log(error);
        }
    };

    render() {
        return (
            <>
                {this.state.loading ? (
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                ) : (
                    ''
                )}
                <div className="content" style={{ marginTop: this.state.hideGroups ? -100 : -200 }}>
                    <div className="login-page">
                        <Container>
                            <Col xs={12} md={6} lg={4} className="ml-auto mr-auto">
                                <Form>
                                    <Card className="card-login card-plain">
                                        <CardHeader
                                            style={{
                                                textAlign: 'center',
                                                paddingBottom: 0,
                                            }}
                                        >
                                            <img src={logoImage} alt="Update Everyone" />
                                        </CardHeader>
                                        <CardHeader
                                            style={{
                                                color: '#731919',
                                                textAlign: 'center',
                                                marginTop: -20,
                                            }}
                                        >
                                            {this.state.hidePhone === false
                                                ? 'Enter your cell phone number to login'
                                                : this.state.hideCode === false
                                                ? 'Enter the 3-digit code we just texted you'
                                                : 'Select the account you want to access'}
                                        </CardHeader>
                                        <CardBody style={{ height: 70 }}>
                                            <InputGroup
                                                className={
                                                    'no-border form-control-lg ' +
                                                    (this.state.hidePhone === false ? 'input-group-focus' : '')
                                                }
                                                style={{
                                                    textAlign: 'center',
                                                    display: this.state.hidePhone === true ? 'none' : '',
                                                }}
                                            >
                                                <Input
                                                    id={'PhoneNumber'}
                                                    name={'PhoneNumber'}
                                                    autoFocus={true}
                                                    style={{
                                                        paddingLeft: 10,
                                                        fontSize: 18,
                                                        height: 45,
                                                        backgroundColor: '#FFFFFF',
                                                        color: '#000000',
                                                        textAlign: 'center',
                                                        maxWidth: 300,
                                                        minWidth: 300,
                                                    }}
                                                    placeholder={'(512) 555-1212'}
                                                    type="tel"
                                                    maxLength="14"
                                                    onChange={(e) =>
                                                        this.setState({
                                                            phoneNumber: this.formatPhone(e.target.value),
                                                            error: '',
                                                        })
                                                    }
                                                    value={this.state.phoneNumber}
                                                />
                                            </InputGroup>
                                            <InputGroup
                                                className={
                                                    'no-border form-control-lg ' +
                                                    (this.state.hideCode === false ? 'input-group-focus' : '')
                                                }
                                                style={{
                                                    display: this.state.hideCode === true ? 'none' : '',
                                                }}
                                            >
                                                <Input
                                                    autoFocus={true}
                                                    style={{
                                                        paddingLeft: 10,
                                                        fontSize: 18,
                                                        height: 45,
                                                        backgroundColor: '#FFFFFF',
                                                        color: '#000000',
                                                        textAlign: 'center',
                                                        maxWidth: 300,
                                                        minWidth: 300,
                                                    }}
                                                    onKeyDown={this._handleKeyDown}
                                                    type="tel"
                                                    placeholder="123"
                                                    maxLength="3"
                                                    value={this.state.code ? this.state.code : ''}
                                                    onChange={(text) => this.codeUpdated(text.target.value)}
                                                />
                                            </InputGroup>
                                            <InputGroup
                                                style={{
                                                    display: this.state.hideGroups === true ? 'none' : '',
                                                }}
                                            >
                                                {this.state.groups}
                                            </InputGroup>
                                        </CardBody>
                                        <CardFooter style={{ display: this.state.hideGroups === false ? 'none' : '' }}>
                                            <Button
                                                block
                                                disabled={this.state.disabled}
                                                onClick={(e) => this.submitForm(null)}
                                                size="lg"
                                                className="mb-3 btn-round"
                                                style={{
                                                    marginLeft: -5,
                                                    width: 300,
                                                    backgroundColor: '#52CBD9',
                                                    display: this.state.hideGroups === false ? 'none' : '',
                                                }}
                                            >
                                                {this.state.disabled
                                                    ? 'One moment...'
                                                    : this.state.hidePhone === false
                                                    ? 'Send me a login code'
                                                    : 'Verify code'}
                                            </Button>
                                            <Label
                                                style={{
                                                    width: '100%',
                                                    textAlign: 'center',
                                                    display: this.state.error.length > 0 ? '' : 'none',
                                                }}
                                            >
                                                {this.state.error}
                                            </Label>
                                            <Label
                                                style={{
                                                    color: '#731919',
                                                    width: '100%',
                                                    textAlign: 'center',
                                                    paddingTop: 10,
                                                    display: this.state.hidePhone === true ? 'none' : '',
                                                }}
                                            >
                                                Need an account? <a href="/a/signup">Sign up here!</a>
                                            </Label>
                                            <Label
                                                style={{
                                                    color: '#731919',
                                                    width: '100%',
                                                    textAlign: 'center',
                                                    paddingTop: 10,
                                                    display: this.state.hidePhone === true ? '' : 'none',
                                                }}
                                            >
                                                By logging in you agree to our{' '}
                                                <a
                                                    href="https://updateeveryone.com/terms"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    terms
                                                </a>
                                            </Label>
                                            <Label
                                                style={{
                                                    color: '#731919',
                                                    width: '100%',
                                                    textAlign: 'center',
                                                    paddingTop: 10,
                                                    display:
                                                        this.state.hideCode === false || this.state.hideGroups === false
                                                            ? ''
                                                            : 'none',
                                                }}
                                            >
                                                Enter the wrong phone number?{' '}
                                                <a href="/a/login?loggedout">Click here</a>
                                            </Label>
                                        </CardFooter>
                                        <CardFooter
                                            style={{
                                                color: '#FFFFFF',
                                                textAlign: 'center',
                                            }}
                                        ></CardFooter>
                                    </Card>
                                </Form>
                            </Col>
                        </Container>
                    </div>
                    <iframe
                        src="https://feelalytics-chat.web.app/"
                        style={{
                            position: 'fixed',
                            height: '225px',
                            right: 0,
                            bottom: 0,
                            zIndex: 100,
                            borderRadius: 10,
                            display: 'none',
                        }}
                        scrolling="no"
                        frameBorder={0}
                    />
                </div>
                <div className="full-page-background" style={{ backgroundImage: 'url(' + bgImage + ')' }} />
            </>
        );
    }
}

export default LoginPage;
