/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';
import { CardHeader, Card, CardBody, Row, Container, Col, InputGroup, Button, Label, Input } from 'reactstrap';
import 'react-phone-input-2/lib/style.css';
import bgImage from 'assets/img/background.png';
import logoImage from 'assets/img/logo.png';
import homeImage from 'assets/img/home/communicate.png';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

class HomePage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Container>
                    <Col xs={12} md={12} lg={12} className="ml-auto mr-auto">
                        <Card className="card-plain">
                            <CardHeader
                                style={{
                                    textAlign: 'center',
                                    paddingBottom: 0,
                                    marginTop: 0,
                                }}
                            >
                                <img src={logoImage} style={{ width: '50%', height: '50%' }} alt="Update Everyone" />
                            </CardHeader>
                            <CardBody style={{ textAlign: 'center' }}>
                                <Label style={{ fontSize: 18, color: 'black', paddingBottom: 20 }}>
                                    Send an SMS to your Update Everyone number and we'll send it to your subscribers.
                                </Label>
                                <InputGroup>
                                    <img src={homeImage} alt="Update Everyone" />
                                </InputGroup>
                            </CardBody>
                        </Card>
                    </Col>
                    <Row style={{ marginTop: -20, marginBottom: 150 }}>
                        <Col xs={12} md={12} lg={12} className="ml-auto mr-auto" style={{ textAlign: 'center' }}>
                            <Button
                                style={{ minWidth: 220, minHeight: 50 }}
                                color="primary"
                                onClick={() => {
                                    this.props.history.push('/a/signup');
                                }}
                            >
                                Sign up for an account
                            </Button>
                        </Col>
                        <Col xs={12} md={12} lg={12} className="ml-auto mr-auto" style={{ textAlign: 'center' }}>
                            <Button
                                style={{ minWidth: 220, minHeight: 50 }}
                                color="primary"
                                onClick={() => {
                                    this.props.history.push('/a/login');
                                }}
                            >
                                Login to your account
                            </Button>
                        </Col>
                    </Row>
                </Container>
                <div className="full-page-background" style={{ backgroundImage: 'url(' + bgImage + ')' }} />
            </>
        );
    }
}

export default HomePage;
