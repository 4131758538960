/*!
 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Coded by Creative Tim
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React from 'react';

// reactstrap components
import { Form, FormGroup, Label, Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import 'react-dropdown/style.css';
import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';
import ReactGA from 'react-ga';
import ReactTable from 'react-table';
import moment from 'moment';

const queryString = require('query-string');

class DashboardForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            redirectAfter: '',
            error: '',
            phoneNumber: '',
            phoneNumberState: '',
            data: [],
            loading: true,
            groupId: localStorage.getItem('groupId'),
            text: '',
            copied: '',
            privateLabelObject: { id: null, brandName: 'Update Everyone' },
        };
    }

    formatPhone(value) {
        // return nothing if no value
        if (!value) {
            return value;
        }

        // only allows 0-9 inputs
        var currentValue = value.replace(/[^\d]/g, '');
        var cvLength = currentValue.length;

        // returns: "x", "xx", "xxx"
        if (cvLength < 4) {
            return currentValue;
        }
        // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
        else if (cvLength < 7) {
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        } else {
            // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    }

    componentDidMount() {
        //are we logged in?
        //yes
        if (
            localStorage.getItem('LOGGEDIN') &&
            localStorage.getItem('LOGGEDIN') === 'true' &&
            localStorage.getItem('DATEEXPIRES') &&
            new Date(localStorage.getItem('DATEEXPIRES')) > new Date()
        ) {
            ReactGA.pageview(window.location.pathname + window.location.search);

            //grab the querystring
            var qs = queryString.parse(this.props.location.search);

            var privateLabelString = localStorage.getItem('privateLabel');
            var privateLabel = null;

            if (privateLabelString) {
                privateLabel = JSON.parse(privateLabelString);
            }

            this.setState({
                groupId: localStorage.getItem('groupId'),
                //set this to tell us where to go after
                redirectAfter: qs['redirectAfter'] ? qs['redirectAfter'] : '',
                privateLabelObject: privateLabel !== null ? privateLabel : this.state.privateLabelObject,
            });

            this.loadData();
        }
        //are we logged in?
        //no
        else {
            this.props.history.push('/a/logout');
        }
    }

    loadData() {
        // GET request using fetch with error handling
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                SECRET: localStorage.getItem('SECRET'),
                PASSPHRASE: localStorage.getItem('PASSPHRASE'),
            },
        };

        fetch(
            window.configuration.TextResponseURL +
                '?startDate=' +
                moment().add(-1, 'days').utc().format('MM-DD-YYYY') +
                '&endDate=' +
                moment().add(1, 'days').utc().format('MM-DD-YYYY'),
            requestOptions,
        )
            .then(async (response) => {
                //did we get an error?
                //yes
                if (!response.ok) {
                    //did we get an unauthorized?
                    //yes
                    if (response.status === 401) {
                        this.props.history.push('/a/logout');
                    }
                    return null;
                }
                //did we get an error?
                //no
                else {
                    var responses = await response.json();

                    //create our rows
                    var responseObjects = responses.map(function (textResponse) {
                        return {
                            id: textResponse.id,
                            dateAdded: moment(
                                new Date(textResponse.dateAdded).toLocaleDateString() +
                                    ' ' +
                                    new Date(textResponse.dateAdded).toLocaleTimeString(),
                            ).format('ddd @ h:mm a'),
                            text: textResponse.text,
                            from: textResponse.name
                                ? textResponse?.name
                                : '(' +
                                  textResponse.fromPhoneNumber.slice(0, 3) +
                                  ') ' +
                                  textResponse.fromPhoneNumber.slice(3, 6) +
                                  '-' +
                                  textResponse.fromPhoneNumber.slice(6, 10),
                        };
                    });

                    //grab our response
                    this.setState({
                        data: responseObjects,
                        loading: false,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    error: error,
                    loading: false,
                });
                return null;
            });
    }

    phoneUpdated(phone) {
        let reg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (reg.test(phone.substring(1)) === false) {
            this.setState({
                phoneNumber: phone,
                phoneNumberState: 'has-danger',
            });
        } else {
            this.setState({
                phoneNumber: phone,
                phoneNumberState: 'has-success',
            });
        }
    }

    _handleKeyDown = (e) => {
        try {
            //did they press enter?
            //yes
            if (e.key === 'Enter') {
                this.submitForm(null, null);
            }
        } catch (error) {
            console.log(error);
        }
    };

    //this submits the form
    submitForm(e, navigation) {
        //did we disable the button?
        //yes
        if (this.state.disabled) {
            return;
        }

        //do we have a phone number?
        //no
        if (this.state.phoneNumber.length < 10) {
            this.setState({
                error: 'Enter the full number with area code',
            });
            return;
        }

        this.setState({
            disabled: true,
            error: '',
            loading: true,
        });

        // POST request using fetch with error handling
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                SECRET: localStorage.getItem('SECRET'),
                PASSPHRASE: localStorage.getItem('PASSPHRASE'),
            },
            body: JSON.stringify({
                groupId: this.state.groupId,
                phoneNumber: this.state.phoneNumber,
                siteId: window.configuration.siteId,
            }),
        };

        fetch(window.configuration.MemberURL, requestOptions)
            .then(async (response) => {
                // check for error response
                //did we get an error?
                //yes
                if (!response.ok) {
                    //did we get an unauthorized?
                    //yes
                    if (response.status === 401) {
                        this.props.history.push('/a/logout');
                    }
                    //did we get an unauthorized?
                    //no
                    else {
                        //set the error
                        this.setState({
                            error: 'We were unable to add the subscriber',
                            disabled: false,
                            loading: false,
                        });
                    }
                }
                //did we get an error?
                //no
                else {
                    var results = await response.json();

                    //did it work?
                    //yes
                    if (results.success === true) {
                        //send them to the subscribers list unless we have a different location
                        this.props.history.push(
                            this.state.redirectAfter ? this.state.redirectAfter : '/in/l/subscribers',
                        );
                        return;
                    }
                    //did it work?
                    //no
                    else {
                        //set the error
                        this.setState({
                            error: results.error,
                            disabled: false,
                            loading: false,
                        });
                    }
                }
            })
            .catch((error) => {
                this.setState({
                    error: error,
                    disabled: false,
                    loading: false,
                });
            });
    }

    render() {
        //are we loading?
        //yes
        if (this.state.loading === true) {
            return (
                <>
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                </>
            );
        }
        return (
            <>
                {this.state.loading ? (
                    <Loader
                        height="0"
                        width="0"
                        type="ThreeDots"
                        color="#00BFFF"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            background: '#99CCFF',
                            opacity: '0.6',
                            zIndex: '998',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                ) : (
                    ''
                )}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12} style={{ maxWidth: 910 }}>
                            <Form>
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Update Everyone</CardTitle>
                                        <CardTitle>
                                            Here are the recent replies sent to your Update Everyone number
                                            {' ' + this.formatPhone(localStorage.getItem('smsPhoneNumber'))}.
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <ReactTable
                                            data={this.state.data}
                                            filterable={false}
                                            columns={[
                                                {
                                                    Header: 'Received',
                                                    accessor: 'dateAdded',
                                                    width: 120,
                                                    sortable: false,
                                                    filterable: false,
                                                },
                                                {
                                                    Header: 'From',
                                                    accessor: 'from',
                                                    width: 150,
                                                    sortable: false,
                                                    filterable: false,
                                                },
                                                {
                                                    Header: '',
                                                    accessor: 'text',
                                                    sortable: false,
                                                    style: { whiteSpace: 'unset' },
                                                    filterable: false,
                                                },
                                            ]}
                                            defaultPageSize={this.state.data.length}
                                            showPaginationBottom={false}
                                            className="-striped -highlight"
                                        />
                                        <Label
                                            style={{
                                                fontSize: 14,
                                                marginTop: 10,
                                                paddingLeft: 5,
                                                display: this.state.data.length === 0 ? '' : 'none',
                                            }}
                                        >
                                            No recent replies
                                            <br />
                                            <br />
                                        </Label>
                                    </CardBody>
                                    <Label
                                        style={{
                                            width: '100%',
                                            paddingTop: 40,
                                            paddingRight: 30,
                                            paddingBottom: 30,
                                            textAlign: 'right',
                                            display: this.state.privateLabelObject.id === null ? 'none' : '',
                                        }}
                                    >
                                        powered by{' '}
                                        <a href="https://updateeveryone.com" target="_blank" rel="noopener noreferrer">
                                            Update Everyone
                                        </a>
                                    </Label>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default DashboardForm;
